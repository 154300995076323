<script setup>
import CoinSelectSelf from "@/components/main/CoinSelectSelf.vue";
import { computed, inject, onBeforeMount, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import Analytics from "@/api/Analytics.js";
import { useToast } from "vue-toast-notification";
import { useStore } from "vuex";
import numeral from "numeral";

const store = useStore();

const axios = inject("axios");
const router = useRouter();
const currRoute = useRoute();
const $toast = useToast();
const defaultSendTicker = { ticker: "btc" };
const defaultReceiveTicker = { ticker: "eth" };

const sendCoin = ref(null);
const receiveCoin = ref(null);
const sendAmount = ref(0);
const receivingAmount = ref(0);
const destAddress = ref("");
const exchangeEstimate = ref({
  estimatedAmount: "0",
  transactionSpeedForecast: null,
});
const exchangeError = ref(null);
const isSendCoinLoading = ref(false);
const isReceivingCoinLoading = ref(false);
const addressError = ref(null);
const amountRange = ref({ min: null, max: null });
const isConfigLoading = ref(false);
const exchangeConfig = computed(() => store.state.exchangeConfig);

const isReceivingCoinLoaderActive = computed(() => {
  return isReceivingCoinLoading.value || receivingAmount.value <= 0;
});

const isSubmitButtonDisabled = computed(() => {
  return (
    isSendCoinLoading.value ||
    isReceivingCoinLoaderActive.value ||
    isConfigLoading.value ||
    !destAddress.value.trim() ||
    exchangeError.value
  );
});

const receivingCoinList = computed(() => {
  if (receiveCoin.value) {
    return getAvailableCoinList(receiveCoin.value.ticker);
  }
  return [];
});

const sendingCoinList = computed(() => {
  if (sendCoin.value) {
    return getAvailableCoinList(sendCoin.value.ticker);
  }
  return [];
});

const isReceivingCoinAvailable = computed(() => {
  return sendCoin.value || !isReceivingCoinLoaderActive.value;
});

const isExchangeEstimateShown = computed(() => {
  if (exchangeEstimate.value) {
    return (
      exchangeEstimate.value.estimatedAmount > 0 &&
      exchangeEstimate.value.transactionSpeedForecast
    );
  }
  return false;
});

const getAvailableCoinList = (ticker) => {
  return exchangeConfig.value.filter((coin) => coin.ticker !== ticker);
};

const getConfig = async (sendingTicker, receivingTicker) => {
  isConfigLoading.value = true;
  await axios
    .get("exchange/config")
    .then(({ data }) => {
      store.dispatch("setExchangeConfig", data);
      const ticker1 = data.find((c) => c.ticker === sendingTicker);
      const ticker2 = data.find((c) => c.ticker === receivingTicker);
      if (ticker1) {
        sendCoin.value = ticker1;
      }
      if (ticker2) {
        receiveCoin.value = ticker2;
      }
      isConfigLoading.value = false;
    })
    .catch(() => $toast.error("Something went wrong!"));
};

const getPairRange = async (sendingCoinValue, receivingCoinValue) => {
  if (sendingCoinValue && receivingCoinValue) {
    const exchange_pair = `${sendingCoinValue.ticker}_${receivingCoinValue.ticker}`;
    await axios
      .get(`/exchange/range`, {
        params: {
          exchange_pair,
        },
      })
      .then(({ data }) => {
        if (data?.error) {
          setExchangeError(data);
        } else {
          amountRange.value = { min: data.minAmount || 1, max: data.maxAmount };
        }
      });
  }
};

const getExchangeEstimation = async () => {
  if (sendCoin.value && receiveCoin.value && sendAmount.value > 0) {
    await axios
      .get("exchange/estimate", {
        params: {
          exchange_pair: `${sendCoin.value.ticker}_${receiveCoin.value.ticker}`,
          amount: sendAmount.value,
        },
      })
      .then(({ data }) => {
        if (data?.error) {
          setExchangeError(data);
        }
        receivingAmount.value = data.estimatedAmount;
        exchangeEstimate.value = data;
      });
  }
};

const setExchangeError = (data) => {
  if (data?.message) {
    exchangeError.value = data.message;
  } else {
    exchangeError.value = data.error;
  }
};

const nullifyExchangeError = () => {
  exchangeError.value = null;
};

const onCoinsSwap = async () => {
  nullifyExchangeError();
  isSendCoinLoading.value = true;
  isReceivingCoinLoading.value = true;
  const tempSendingCoin = sendCoin.value;
  sendAmount.value = receivingAmount.value;
  sendCoin.value = receiveCoin.value;
  receiveCoin.value = tempSendingCoin;
  await getPairRange(sendCoin.value, receiveCoin.value);
  await getExchangeEstimation();
  isSendCoinLoading.value = false;
  isReceivingCoinLoading.value = false;
};

const setSendingAmount = async (value) => {
  isReceivingCoinLoading.value = true;
  if (amountRange.value) {
    if (value < amountRange.value.min) {
      sendAmount.value = amountRange.value.min;
    } else if (amountRange.value.max && value > amountRange.value.max) {
      sendAmount.value = amountRange.value.max;
    } else {
      sendAmount.value = value;
    }
  } else {
    sendAmount.value = 1;
  }
  await getExchangeEstimation();
  isReceivingCoinLoading.value = false;
};

const handleSendCoinSelect = async (coin) => {
  sendCoin.value = coin;
  await handleCoinSelect();
};

const handleReceivingCoinSelect = async (coin) => {
  receiveCoin.value = coin;
  await handleCoinSelect();
};

const handleCoinSelect = async () => {
  nullifyExchangeError();
  isSendCoinLoading.value = true;
  isReceivingCoinLoading.value = true;
  await getPairRange(sendCoin.value, receiveCoin.value);
  await getExchangeEstimation();
  isSendCoinLoading.value = false;
  isReceivingCoinLoading.value = false;
};

const onStartExchange = (e) => {
  e.preventDefault();
  const data = {
    amount: sendAmount.value,
    from: sendCoin.value.ticker,
    to: receiveCoin.value.ticker,
    receiving_address: destAddress.value,
  };

  axios
    .post("exchange", data)
    .then(({ data }) => {
      const id = data.id;
      router.push({
        name: "order",
        params: { id: id },
        query: { deposit_address: data.deposit_address },
      });
    })
    .catch(({ response }) => {
      if (response.data?.error === "not_valid_address") {
        addressError.value = "Destination address is invalid";
        return;
      }
      $toast.error("Something went wrong!");
    });
  return false;
};

const onClearDestAddress = () => (destAddress.value = "");

const onPasteDestAddress = () => {
  navigator.clipboard.readText().then((text) => (destAddress.value = text));
};

const setDefaultTickers = () => {
  if (currRoute.query.from) {
    defaultSendTicker.ticker = currRoute.query.from;
  }
  if (currRoute.query.to) {
    defaultReceiveTicker.ticker = currRoute.query.to;
  }
};

onBeforeMount(async () => {
  Analytics.pushEvent("home");
  setDefaultTickers();
  isSendCoinLoading.value = true;
  await getConfig(defaultSendTicker.ticker, defaultReceiveTicker.ticker);
  await getPairRange(sendCoin.value, receiveCoin.value);
  await getExchangeEstimation();
  isSendCoinLoading.value = false;
});

watch(amountRange, ({ min }) => {
  if (min > sendAmount.value) {
    sendAmount.value = min;
  }
});
</script>

<template>
  <form class="exchange-form">
    <div class="exchange-amounts">
      <div class="col">
        <CoinSelectSelf
          :title="'Send'"
          :coins="sendingCoinList"
          :coin="sendCoin"
          :disabled="false"
          :amount="sendAmount"
          :min-amount="amountRange.min"
          :loading="isSendCoinLoading"
          :focusable="true"
          :editable="!exchangeError"
          :amount-usd-in="exchangeEstimate.amount_usd_in"
          @change-coin="handleSendCoinSelect($event)"
          @change-amount="setSendingAmount($event)"
        ></CoinSelectSelf>
        <span v-if="exchangeError" class="error-hint">{{ exchangeError }}</span>
      </div>
      <div class="col-middle">
        <button
          class="btn-reverse"
          @click="onCoinsSwap"
          id="btn_reverse"
          type="button"
        >
          <span id="btn_reverse_from" class="btn-reverse-exch ico"></span>
          <span id="btn_reverse_to" class="btn-reverse-receive ico"></span>
        </button>
      </div>
      <div class="col">
        <CoinSelectSelf
          title="Receive"
          :coins="receivingCoinList"
          :coin="receiveCoin"
          :disabled="!isReceivingCoinAvailable"
          :editable="false"
          :loading="isReceivingCoinLoaderActive"
          :amount="receivingAmount"
          :amount-usd-out="exchangeEstimate.amount_usd_out"
          @change-coin="handleReceivingCoinSelect($event)"
        ></CoinSelectSelf>
      </div>
    </div>
    <div class="exchange-estimate" v-if="isExchangeEstimateShown">
      <p>
        <template
          v-if="parseFloat(exchangeEstimate.estimatedAmount) > 0 && receiveCoin"
        >
          You get
          <b
            >{{
              numeral(parseFloat(exchangeEstimate.estimatedAmount)).format(
                "0.[0000]"
              )
            }}
            {{ receiveCoin.ticker.toUpperCase() }}</b
          >.
        </template>
        <template v-if="exchangeEstimate?.transactionSpeedForecast">
          Expected processing time
          <b>{{ exchangeEstimate.transactionSpeedForecast }}</b> min
        </template>
      </p>
      <p class="usdt-est">
        You send
        <b
          >{{
            numeral(exchangeEstimate.amount_usd_in).format("0.[0000]")
          }}
          USDT</b
        >
        - you get
        <b
          >{{
            numeral(exchangeEstimate.amount_usd_out).format("0.[0000]")
          }}
          USDT</b
        >
      </p>
    </div>
    <div class="exchange-address-wrap">
      <div class="wrap-header">Destination</div>
      <div class="field">
        <textarea
          class="nonextra"
          :class="{ error: addressError }"
          required=""
          id="receive_wallet"
          autocomplete="off"
          placeholder="Your address"
          v-model="destAddress"
        ></textarea>
        <div class="extra">
          <button
            type="button"
            class="ico paste hoverhl"
            id="wallet_paste"
            title="Paste"
            @click="onPasteDestAddress"
          ></button>
          <button
            type="button"
            v-if="false"
            class="ico scanqr hoverhl"
            id="wallet_scanqr"
            title="Scan QR code"
          ></button>
          <button
            type="button"
            :style="{ visibility: destAddress ? 'visible' : 'hidden' }"
            class="ico close hoverhl"
            id="wallet_clear"
            @click="onClearDestAddress"
          ></button>
        </div>
      </div>
      <span v-if="addressError" class="error-hint">{{ addressError }}</span>
      <div class="exchange-wrapflex">
        <div class="exchange-option" v-if="false">
          <header>Order type</header>
          <div class="exchange-option-inner">
            <label class="radioselect">
              <input
                type="radio"
                name="select_type_from"
                value="fixed"
                id="fixed_type"
              />
              <span>Fixed rate (1.0%)</span>
            </label>
            <label class="radioselect">
              <input
                type="radio"
                name="select_type_from"
                value="float"
                id="float_type"
                checked=""
              />
              <span>Float rate (0.5%)</span>
            </label>
          </div>
          <span id="type_difference" class="exchange-option-diff">
            <div class="exchange-option-diff-inner">
              <i>?</i>
              <span>What is the difference?</span>
            </div>
          </span>
        </div>

        <div class="exchange-button">
          <button
            id="exchange_submit"
            class="exchange-submit"
            type="button"
            :disabled="isSubmitButtonDisabled"
            @click.prevent="onStartExchange"
          >
            <span>Exchange now</span>
          </button>
        </div>
      </div>
    </div>
    <div class="exchange-terms">
      By using the site and creating an exchange, you agree to the
      CurrencyExchange's <a href="/terms-of-service">Terms of Services</a> and
      <a href="/privacy-policy">Privacy Policy</a>
    </div>
  </form>
</template>

<style scoped>
div {
  position: relative;
  margin: 2.2em auto 0;
  width: 100%;
}

.exchange-amounts {
  margin: 0 0 2.2em;
  display: flex;
  justify-content: space-between;
  z-index: 102;
  position: relative;
  width: 100%;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }

  .col {
    box-sizing: border-box;
    margin-top: 0;

    .error-hint {
      font-size: 12px;
      color: red;
      display: block;
      margin-top: 5px;
    }
  }

  .col-middle {
    margin-top: 0;
    max-width: 108px;
    box-sizing: border-box;
    text-align: center;
    z-index: 12;
    padding: 0.3em 1.1em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 600px) {
      padding: 1.3em 1.1em 0;
    }

    .btn-reverse {
      background: none;
      border: 0;
      color: #fff;
      cursor: pointer;
      font-size: 1.1em;
      height: 2.25em;
      position: relative;
      width: 2.25em;
      @media only screen and (max-width: 600px) {
        transform: rotate(90deg);
      }

      .btn-reverse-exch.ico,
      .btn-reverse-receive.ico {
        transform: translate(0.25em, 0.2em);
        display: block;
        padding: 0;
        transition: color 0.3s ease;
        will-change: color;
        position: relative;
        font-family: "Icons";
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        text-align: center;

        &:after {
          content: "\e200";
        }
      }

      .btn-reverse-receive.ico {
        transform: translate(-0.25em, -0.2em) rotate(180deg);
      }
    }
  }
}

.exchange-estimate {
  text-align: center;
  .usdt-est {
    margin-top: 10px;
  }
}

.exchange-address-wrap {
  width: 100%;
  display: block;
  position: relative;
  margin: 2.2em auto 0;

  .wrap-header {
    padding: 0 1.2em 0.4em;
    color: #ccc;
    font-size: 0.9em;
    text-align: left;
    transition: color 0.3s ease;
    will-change: color;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
  }

  .field {
    position: relative;
    border-radius: 0.5rem;
    margin: 0.2em 0 0;

    textarea.nonextra {
      text-overflow: ellipsis;
      box-sizing: border-box;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 0.5rem;
      border: 1px solid transparent;
      color: inherit;
      font-size: 1.1em;
      line-height: 1;
      padding: 1.3em 2.4em 1.05em 1em;
      width: 100%;
      resize: none;
      display: block;
      overflow: hidden;
      height: 58px;

      @media only screen and (max-width: 600px) {
        height: 44px;
        font-size: 1em;
        padding: 1.3em 4.4em 1.05em 1em;
      }

      &.error {
        border: 1px solid red;
      }
    }

    .extra {
      bottom: 0.3em;
      display: flex;
      justify-content: center;
      min-width: 2.4em;
      width: auto;
      position: absolute;
      right: 0.3em;
      top: 0.3em;
      margin: 0;

      button {
        background-color: transparent;
        border: 0;
        color: #fff;
        cursor: pointer;
        display: flex;
        font-size: 1.05em;
        justify-content: center;
        align-items: center;
        padding: 0.3em 0.5em;
        width: 100%;
        box-sizing: border-box;

        &.ico {
          font-family: "Icons";
          font-style: normal;
          font-weight: normal;
          line-height: 1;

          text-align: center;
          vertical-align: middle;
        }

        &.paste:after {
          content: "\e206";
          font-size: 1.4em;
        }

        &.scanqr:after {
          content: "\e207";
          font-size: 1.4em;
        }

        &.close:after {
          content: "\e10D";
          font-size: 0.7em;
        }
      }
    }
  }

  .error-hint {
    font-size: 12px;
    color: red;
    display: block;
    margin-top: 5px;
  }

  .exchange-wrapflex {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 2.2em auto 0;
    width: 100%;

    .exchange-option {
      display: flex;
      align-items: center;
      margin: 0;

      header {
        padding-right: 0.9em;
        font-weight: 600;
      }

      .exchange-option-inner {
        box-sizing: border-box;
        display: flex;
        min-width: 24.9em;
        width: auto;
        margin: 0;

        .radioselect {
          cursor: pointer;
          display: inline-block;
          margin: 0;
          position: relative;
          width: 50%;

          &:last-child {
            span {
              border-radius: 0 0.6em 0.6em 0;
            }
          }

          input {
            position: absolute;
            visibility: hidden;
            z-index: -999;

            &:checked + span {
              color: #008fdf;
              border-color: #008fdf;
              font-weight: 600;
              z-index: 1;
              opacity: 1;
            }
          }

          span {
            border-radius: 0.6em 0 0 0.6em;
            box-sizing: border-box;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.5);
            border: 1px solid transparent;
            display: flex;
            font-size: 1em;
            height: 3.575em;
            color: rgba(255, 255, 255, 0.45);
            justify-content: center;
            opacity: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .exchange-option-diff {
        cursor: pointer;
        display: block;
        -webkit-tap-highlight-color: transparent;
        color: #fff;
        white-space: nowrap;
        height: 2em;
        position: relative;
        z-index: 10;
        width: 3em;
        margin-left: 0.5em;

        &:hover > div {
          max-width: 20em;
          width: auto;
          background-color: rgba(0, 0, 0, 0.8);

          i {
            color: #008fdf;
          }
        }

        .exchange-option-diff-inner {
          width: auto;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          height: 100%;
          overflow: hidden;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 1em;
          max-width: 2em;
          transition: max-width 0.3s ease, background-color 0.3s ease;
          margin: 0;

          i {
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            text-align: center;
            vertical-align: middle;
            display: block;
            min-width: 2em;
            background-color: transparent;
          }

          font {
            font-size: 0.8em;
            padding-right: 0.9em;
          }
        }
      }
    }

    .exchange-button {
      text-align: center;
      width: auto;
      margin: 0;

      .exchange-submit {
        background-color: #fff;
        border-radius: 0.5em;
        border: 0;
        cursor: pointer;
        display: inline-block;
        font-size: 1em;
        overflow: hidden;
        height: 3.575em;
        padding: 0;
        transition: opacity 0.4s ease;
        will-change: opacity;

        &:disabled {
          cursor: unset;
          opacity: 0.6;
        }

        span {
          width: auto;
          box-sizing: border-box;
          align-items: center;
          justify-content: center;
          background-color: #008fdf;
          color: #fff;
          display: flex;
          font-size: 1em;
          font-weight: 600;
          height: 100%;
          padding: 0.73em 3em;
        }
      }
    }
  }
}

.exchange-wrapflex {
  display: flex;
  justify-content: space-between;
}

.exchange-terms {
  margin-top: 1em;
  font-size: 0.85em;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 300;

  a {
    color: #018fdf;
    font-weight: 600;
  }
}
</style>
