<script setup>
import { inject, ref, computed, defineEmits } from "vue";
import { useRouter } from "vue-router";
import LangSelect from "@/components/shared/header/LangSelect.vue";
import Logo from "@/components/shared/header/Logo.vue";
import MainMenu from "@/components/shared/header/MainMenu.vue";
import LeftMenu from "@/components/shared/header/LeftMenu.vue";
import Modal from "@/components/shared/modal/Modal.vue";
import AuthModal from "@/components/shared/modal/AuthModal.vue";
import Container from "@/components/shared/Container.vue";
import { Auth } from "@/services/Auth.js";
import { useStore } from "vuex";

const store = useStore();

const isAuthenticated = ref(false);
const selectedLocale = ref("en");
const showAuth = ref(false);
const authTab = ref("in");
const profile = computed(() => store.state.profile);

const authService = new Auth(inject("axios"), inject("$cookies"));
const router = useRouter();

const emit = defineEmits(["logout"]);

const setProfileDispatch = (payload) => {
  store.dispatch("setProfile", payload);
};

const onShowAuth = (action) => {
  authTab.value = action;
  showAuth.value = true;
};

const onLogout = () => {
  authService.logout().then(() => {
    setProfileDispatch(null);
    router.push("/");
  });
};

const getProfile = async () => {
  await authService
    .profile()
    .then(({ data }) => {
      setProfileDispatch(data);
    })
    .catch(() => {
      setProfileDispatch(null);
    });
};

const onAuthClosed = () => (showAuth.value = false);

const onAuthDone = async () => {
  showAuth.value = false;
  await getProfile();
  router.push("/");
};

getProfile();
</script>

<template>
  <header id="header">
    <Container>
      <LeftMenu @logout="onLogout"></LeftMenu>
      <nav class="header-nav">
        <Logo></Logo>
        <div class="nav-right">
          <MainMenu></MainMenu>
          <LangSelect v-if="false"></LangSelect>
          <div class="nav userbar hoverhl" v-if="!profile">
            <a
              class="btn-text"
              @click.prevent="onShowAuth('in')"
              href="/sign-in"
              >Sign in</a
            >
            <a class="btn" @click.prevent="onShowAuth('up')" href="/sign-up"
              >Sign up</a
            >
          </div>
          <div class="nav userbar hoverhl" v-if="profile">
            <a class="btn-text">{{ profile?.email }}</a>
            <a class="btn" @click.prevent="onLogout">Logout</a>
          </div>
        </div>
      </nav>
      <div class="shadow-body"></div>
    </Container>
  </header>
  <Modal :active="showAuth" @closed="onAuthClosed()">
    <AuthModal :tab="authTab" @done="onAuthDone()"></AuthModal>
  </Modal>
</template>

<style scoped>
header#header {
  position: relative;
  z-index: 100;

  .wrapper {
    max-width: initial;
    padding: 0 2em;
    width: auto;
  }
  nav {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: space-between;
  }
  .nav-right {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.nav.userbar {
  display: flex;
  margin-left: 1em;
  padding-left: 0.9em;
  position: relative;
  height: 3.9em;
  align-items: center;
}

.nav.userbar > .btn,
.nav.userbar > .btn-text {
  white-space: nowrap;
}

.nav.userbar > .btn-text:hover {
  color: inherit;
  text-decoration: none;
}

.nav.userbar > .btn-text:last-of-type {
  padding-right: 0;
}

.header-nav {
  @media screen and (max-width: 768px) {
    padding: 0.5em 0;
  }
}

.nav.userbar:before {
  background-color: #fff;
  content: "";
  height: 1.8em;
  left: 0;
  position: absolute;
  top: 50%;
  width: 1px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.nav.userbar > .btn.submit {
  border-color: #fff;
  border-width: 1px;
  font-size: 1em;
  height: auto;
  margin: 0.6em 0;
  min-width: auto;
  padding: 0.85em 1em;
}

.nav.userbar > button {
  float: left;
}

.btn-text {
  background-color: transparent;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
  line-height: 1em;
}

.btn {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #008fdf;
  border-radius: 0.25em;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 1em;
  font-weight: 600;
  line-height: 1em;
  position: relative;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}

.btn,
.btn-text {
  padding: 1em 1.5em;
}

.menu-focus-opened {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #21284b;
  border-radius: 0.5rem;
  color: #808191;
  margin-top: -0.8em;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 100%;
  visibility: hidden;
  z-index: 10;
  -webkit-transition: visibility 0s ease 0.15s, opacity 0.15s ease,
    margin 0.15s ease;
  -o-transition: visibility 0s ease 0.15s, opacity 0.15s ease, margin 0.15s ease;
  transition: visibility 0s ease 0.15s, opacity 0.15s ease, margin 0.15s ease;
}

.menu-focus-opened a {
  border-radius: 4px;
  color: #808191;
  border-radius: 0.5rem;
  display: flex;
  line-height: 1;
  padding: 1em 2.5em 1em 1.25em;
  white-space: nowrap;
  -webkit-transition: background 0.25s;
  -o-transition: background 0.25s;
  transition: background 0.25s;
  align-items: center;
}

.menu-focus-opened a:hover {
  color: #008fdf;
  background-color: rgba(0, 143, 223, 0.2);
}

.menu-focus-opened a:active {
  color: #008fdf;
  background-color: transparent;
}

.menu-focus-opened a > i.ico {
  margin-right: 1em;
  display: block;
}

.menu-focus-opened a > i.ico:after {
  font-size: 1.5em;
  width: 1em;
}

.menu-focus-opened a > span {
  font-size: 0.875em;
  font-weight: 600;
}

.menu-focus-btn:focus + .menu-focus-opened,
.menu-focus-opened:hover {
  margin-top: -0.5em;
  opacity: 1;
  visibility: visible;
}

.menu-focus-opened .langs {
  margin-left: 0.8em;
  border-radius: 0.3em;
  height: 1.3em;
  background-size: 1.8em auto;
}

.menu-focus-opened a.signout {
}

.menu-focus-opened a.signout:hover {
}
@media only screen and (max-width: 900px) {
  ul.nav.menu {
    li {
      a {
        padding: 0 0.5em !important;
      }
    }
  }
}

@media only screen and (max-width: 740px) {
  body header#header {
    background-color: #0c0d16;
    box-shadow: 0 0 8px -2px #000;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    -webkit-transition: background-color 0.3s ease, box-shadow 0.3s ease;
    -o-transition: background-color 0.3s ease, box-shadow 0.3s ease;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    will-change: background-color, box-shadow;
  }

  body header#header > .wrapper,
  body.index header#header > .wrapper {
    padding: 0;
  }

  body header#header.ontop {
    background-color: transparent;
    box-shadow: 0 0 0 -2px #000;
    position: absolute;
  }

  body header#header.onbody {
    position: absolute;
  }

  body header#header ~ main {
    padding-top: 55px;
  }

  header nav {
    text-align: center;
  }

  header .logo {
    display: flex;
    float: none;
    margin: 0 auto;
    text-align: center;
    position: relative;
  }

  ul.nav.menu {
    display: none;
  }

  .menu-focus-opened a > .loc-img {
    margin-right: 0;
  }

  .nav.userbar {
    display: none;
  }
}

body.gray #left_menu_btn > div > span {
  background-color: #1d232a;
}

body.gray #left_menu_btn:hover > div > span {
  background-color: #f4d631;
}

body.gray nav.left-menu {
  background-color: #f5f5f5;
}

body.gray nav.left-menu li > a {
  color: #1d232a;
}

body.gray nav.left-menu ul {
  border-bottom: 1px solid transparent;
}

body.gray nav.left-menu li {
  border-top: 1px solid #bdbdbd;
}

@media (max-width: 740px) {
  .left-menu-wrap {
    display: block;
  }

  .left-menu-wrap.active ~ .shadow-body {
    opacity: 0.7;
    visibility: visible;
  }
}
</style>
