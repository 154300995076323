<script setup>
import { computed, onMounted, reactive, ref, toRef, watch } from "vue";
import { onClickOutside } from "@vueuse/core";
import { VueSpinner } from "vue3-spinners";
import debounce from "lodash.debounce";
const props = defineProps({
  title: String,
  coins: Array,
  coin: null,
  disabled: {
    type: Boolean,
    default: false,
  },
  focusable: {
    type: Boolean,
    default: false,
  },
  amount: {
    type: [Number, String],
  },
  amountUsdIn: {
    required: false,
    default: null,
    type: Number,
  },
  amountUsdOut: {
    required: false,
    default: null,
    type: Number,
  },
  editable: {
    type: Boolean,
    default: true,
  },
  loading: {
    type: Boolean,
  },
  error: {
    type: String,
    default: "",
  },
  minAmount: {
    required: false,
    type: Number,
    default: 0,
  },
});

const _value = ref("0");

watch(_value, (curr) => {
  emit("changeAmount", _value.value);
});

const emit = defineEmits(["changeCoin", "changeAmount"]);

let selectedCoin = toRef(props, "coin");

let dropDownOpen = ref(false);

const amountInput = ref(null);

const target = ref(null);
const searchInput = ref(null);

let searchStr = ref("");

let renderCoins = computed(() => {
  if (searchStr.value.length === 0) {
    return props.coins;
  }
  return props.coins.filter(
    (c) =>
      c.name.toLowerCase().includes(searchStr.value.toLowerCase()) ||
      c.ticker.toLowerCase().includes(searchStr.value.toLowerCase())
  );
});

const isInputDisabled = computed(() => {
  return !props.editable || props.loading;
});

const amountUsd = computed(() => {
  const { amountUsdIn, amountUsdOut } = props;
  const floor = (number) => Math.floor(number * 100) / 100;
  if (amountUsdIn) {
    return `$${floor(amountUsdIn)}`;
  }

  if (amountUsdOut) {
    return `$${floor(amountUsdOut)}`;
  }

  return null;
});

const handleValueChange = debounce((event) => {
  emit("changeAmount", event.target.value);
}, 1700);

onMounted(() => {
  if (props.focusable) {
    amountInput.value.focus();
  }
});

const onSearch = (e) => {
  searchStr.value = e.target.value;
};

onClickOutside(target, (event) => (dropDownOpen.value = false));

const onSelectCoin = (coin) => {
  selectedCoin.value = coin;
  dropDownOpen.value = false;
  emit("changeCoin", coin);
};

const onDropdownOpen = () => {
  if (props.loading) {
    return;
  }
  if (props.disabled) {
    dropDownOpen.value = false;
    return;
  }
  searchInput.value.focus();
  dropDownOpen.value = !dropDownOpen.value;
};

watch(dropDownOpen, (dropDownOpenVal) => {
  if (!dropDownOpenVal && props.focusable) {
    amountInput.value.focus();
  }
});
</script>

<template>
  <div class="select-wrap" :class="{ disabled: props.disabled }">
    <div class="header">
      <div class="direction">
        {{ title }}
      </div>
      <div class="coin" v-if="selectedCoin">
        {{ selectedCoin.name ?? "" }}
      </div>
    </div>
    <div class="input-wrap">
      <vue-spinner size="30" v-if="props.loading" />
      <input
        type="text"
        autocomplete="off"
        maxlength="18"
        :min="minAmount"
        :value="props.amount"
        @input="handleValueChange"
        :disabled="isInputDisabled"
        :class="{ error: props.error && props.error.length > 0 }"
        ref="amountInput"
      />
      <div class="dropdown-opener-wrap" @click="onDropdownOpen">
        <div class="dropdown-opener">
          <div class="selected-coin-id" v-if="selectedCoin">
            {{ selectedCoin.id }}
          </div>
          <img
            class="selected-coin-network"
            v-if="selectedCoin && !props.loading"
            :src="selectedCoin.image"
            alt="coin-network"
          />
        </div>
      </div>
      <div class="dropdown" ref="target" :class="{ open: dropDownOpen }">
        <div class="search-wrap">
          <span class="search-icon"></span>
          <input
            ref="searchInput"
            type="text"
            value=""
            class="search-input"
            @keyup="onSearch"
            placeholder="Type a currency or ticker"
          />
        </div>
        <ul class="coins-list">
          <li
            class="coin"
            v-for="c in renderCoins"
            :key="c.id"
            @click="onSelectCoin(c)"
          >
            <div class="coin-name">
              {{ c.name }}
            </div>
            <div class="coin-ticker">
              <span class="name">{{ c.id }}</span>
              <sup>
                <img :src="c.image" :alt="c.name" />
              </sup>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="amount-usd" v-if="amountUsd">
      {{ amountUsd }}
    </div>
  </div>
</template>

<style scoped>
.select-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin-top: 0;
  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
  .header {
    padding: 0 1.2em 0.4em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    .direction,
    .coin {
      font-size: 0.9em;
      text-align: left;
      transition: color 0.3s ease;
      will-change: color;
      white-space: nowrap;
      overflow: hidden;
      font-weight: 500;
    }
    .coin {
      text-align: right;
    }
  }
  .input-wrap {
    width: 100%;
    position: relative;
    .vue-spinner {
      position: absolute;
      width: 30px;
      height: 30px;
      right: 30px;
      top: 22%;
    }
    input {
      width: 100%;
      padding: 0.3em 4.4em 0.3em 0.7em;
      border: 1px solid transparent;
      font-size: 1.625em;
      height: 2.2em;
      text-align: left;
      border-radius: 0.5rem;
      box-sizing: border-box;
      background: rgba(0, 0, 0, 0.5);
      color: #ffffff;
      &.error {
        border: 1px solid red;
      }
    }
    .dropdown-opener-wrap {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      bottom: 0;
      padding-right: 0.6em;
      height: 2.15em;
      background-color: transparent;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .dropdown-opener {
        display: flex;
        flex-direction: column;
        .selected-coin-id {
          line-height: 1;
          font-weight: 500;
        }
        .selected-coin-network {
          /*background-color: #fff;*/
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 0.3em;
          padding: 0.2em 0.3em;
          margin-top: 0.1em;
          color: #000;
          font-size: 0.6em;
          &[data-network="ETH"]:after {
            content: "\e301";
            font-family: "Icons";
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            text-align: center;
            font-size: 1.2em;
            margin-left: 0.15em;
          }
        }
      }
      &:after {
        content: "";
        border-bottom-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
        border-style: solid;
        border-width: 0.35em 0.35em;
        display: inline-block;
        vertical-align: middle;
        transform: translateY(28%);
        margin-left: 0.5em;
      }
    }
    .dropdown {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
      background-color: #21284b;
      display: flex;
      flex-direction: column;
      border-radius: 0.4em;
      overflow: hidden;
      transition: height 0.3s ease;
      height: 0;
      &.open {
        height: 30em;
      }
      .search-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .search-icon {
          box-sizing: border-box;
          float: left;
          font-size: 1.1em;
          height: 100%;
          margin-right: 0;
          padding: 0.7em 0.8em;
          width: 2.5em;
          display: flex;
          align-items: center;
          font-family: "Icons";
          font-style: normal;
          font-weight: normal;
          line-height: 1;
          text-align: center;
          vertical-align: middle;
          &:after {
            content: "\e113";
          }
        }
        .search-input {
          font-size: 1em;
          padding: 0.3em 0;
          border: 1px solid transparent;
          box-sizing: border-box;
          height: 3.575em;
          min-height: 3em;
          background: none;
          font-weight: 400;
        }
      }
      .coins-list {
        max-height: 30em;
        background-color: #21284b;
        padding-top: 0.3em;
        user-select: none;
        display: block;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;
        &:before {
          content: "";
          height: 1px;
          position: absolute;
          display: block;
          left: 1em;
          right: 1em;
          top: 0;
          background-color: rgba(255, 255, 255, 0.2);
          z-index: 1;
          pointer-events: none;
        }
        .separate {
          color: #636363;
          font-size: 0.8em;
          padding: 0.4em 1.25em;
          display: block;
          margin: 0;
          width: 100%;
        }
        .coin {
          padding: 0.5em 1em;
          border-bottom: 0;
          border-top: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          .coin-name {
            float: right;
            display: flex;
            flex-direction: column;
            align-items: end;
            order: 2;
            justify-content: center;
            min-height: 2em;
          }
          .coin-ticker {
            float: right;
            display: flex;
            flex-direction: column;
            align-items: end;
            order: 2;
            justify-content: center;
            min-height: 2em;
            width: 26px;

            img {
              width: 100%;
            }
            .name {
              font-size: 0.875em;
              font-weight: 600;
            }
            sup {
              display: inline-flex;
              align-items: center;
              border-radius: 0.3em;
              padding: 0.2em 0.3em;
              margin-top: 0.1em;
              font-size: 0.525em;
              top: 0;
              background-color: transparent;
            }
          }
          &:after {
            content: "";
            display: block;
            position: absolute;
            left: 1em;
            right: 1em;
            height: 1px;
            background-color: rgba(255, 255, 255, 0.04);
            bottom: 0;
            width: auto;
            font-size: inherit;
            clear: both;
          }
        }
      }
    }
  }
}

.amount-usd {
  font-size: 0.8em;
  margin-top: 0.7em;
  margin-right: 1.3em;
  text-align: end;
  opacity: 0.7;
}
</style>
